import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import { Helmet } from 'react-helmet'
import useSiteMetadata from '../hooks/use-sitemetadata'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#555',
    },
    info: {
      main: '#11cb5f',
    },
    secondary: {
      main: '#7fdbdb',
    },
  },
})

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata()

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <main>{children}</main>
      </ThemeProvider>
    </>
  )
}
export default Layout
