import React from 'react'

import styled from 'styled-components'

const Hero = ({ img, className, children, big, gradient }) => {
  return (
    <div className={className} big={big} gradient={gradient}>
      {children}
    </div>
  )
}
export default styled(Hero)`
  min-height: ${props => (props.big ? '100vh' : '20vh')};
  background: ${props =>
      props.gradient
        ? 'linear-gradient(rgba(12, 12, 12, 0.4), rgba(0, 0, 0, 0.7))'
        : 'none'},
    ${props => `url(${props.img.src})`};
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
